"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Box", {
  enumerable: true,
  get: function get() {
    return _styledComponents.Box;
  }
});
Object.defineProperty(exports, "Flex", {
  enumerable: true,
  get: function get() {
    return _styledComponents.Flex;
  }
});
exports.Card = exports.Image = exports.Button = exports.Link = exports.Heading = exports.Text = void 0;

var _react = _interopRequireWildcard(require("react"));

var _styledComponents = require("reflexbox/styled-components");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; if (obj != null) { var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var Text = (0, _react.forwardRef)(function (props, ref) {
  return _react["default"].createElement(_styledComponents.Box, _extends({
    ref: ref,
    tx: "text"
  }, props));
});
exports.Text = Text;
var Heading = (0, _react.forwardRef)(function (props, ref) {
  return _react["default"].createElement(_styledComponents.Box, _extends({
    ref: ref,
    as: "h2",
    tx: "text",
    variant: "heading"
  }, props, {
    __css: {
      fontSize: 4,
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading'
    }
  }));
});
exports.Heading = Heading;
var Link = (0, _react.forwardRef)(function (props, ref) {
  return _react["default"].createElement(_styledComponents.Box, _extends({
    ref: ref,
    as: "a",
    variant: "link"
  }, props));
});
exports.Link = Link;
var Button = (0, _react.forwardRef)(function (props, ref) {
  return _react["default"].createElement(_styledComponents.Box, _extends({
    ref: ref,
    as: "button",
    tx: "buttons",
    variant: "primary"
  }, props, {
    __css: {
      appearance: 'none',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 'inherit',
      textDecoration: 'none',
      fontSize: 'inherit',
      px: 3,
      py: 2,
      color: 'white',
      bg: 'primary',
      border: 0,
      borderRadius: 4
    }
  }));
});
exports.Button = Button;
var Image = (0, _react.forwardRef)(function (props, ref) {
  return _react["default"].createElement(_styledComponents.Box, _extends({
    ref: ref,
    as: "img"
  }, props, {
    __css: {
      maxWidth: '100%',
      height: 'auto'
    }
  }));
});
exports.Image = Image;
var Card = (0, _react.forwardRef)(function (props, ref) {
  return _react["default"].createElement(_styledComponents.Box, _extends({
    ref: ref,
    variant: "card"
  }, props));
});
exports.Card = Card;