"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Flex = exports.Box = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _css = _interopRequireWildcard(require("@styled-system/css"));

var _shouldForwardProp = _interopRequireDefault(require("@styled-system/should-forward-prop"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; if (obj != null) { var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var sx = function sx(props) {
  return (0, _css["default"])(props.sx)(props.theme);
};

var base = function base(props) {
  return (0, _css["default"])(props.__css)(props.theme);
};

var variant = function variant(_ref) {
  var theme = _ref.theme,
      variant = _ref.variant,
      _ref$tx = _ref.tx,
      tx = _ref$tx === void 0 ? 'variants' : _ref$tx;
  return (0, _css["default"])((0, _css.get)(theme, tx + '.' + variant, (0, _css.get)(theme, variant)))(theme);
};

var Box = (0, _styledComponents["default"])('div', {
  shouldForwardProp: _shouldForwardProp["default"]
})({
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0
}, base, variant, sx, function (props) {
  return props.css;
}, (0, _styledSystem.compose)(_styledSystem.space, _styledSystem.layout, _styledSystem.typography, _styledSystem.color, _styledSystem.flexbox));
exports.Box = Box;
var Flex = (0, _styledComponents["default"])(Box)({
  display: 'flex'
});
exports.Flex = Flex;